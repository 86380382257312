@import "tailwindcss";

@plugin "tailwind-scrollbar" {
    nocompatible: true;
}

@theme {
  --font-sans: "Roboto", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@custom-variant dark (&:where(.dark, .dark *));

@layer base {
  html, body {
    @apply font-sans focus-visible:ring-0 scroll-smooth;
    @apply scrollbar scrollbar-thumb-blue-600/75 scrollbar-thumb-rounded-full scrollbar-track-[#111936];
  }

  :focus {
    @apply outline-hidden;
  }

  :focus-visible {
    @apply ring-2 dark:ring-white;
  }

  ::selection {
    @apply bg-sky-700 text-white;
  }

  ::-moz-selection {
    @apply bg-sky-700 text-white;
  }

  button {
    @apply cursor-pointer;
  }
}

@layer components {
  .mask {
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  .mask-hexagon {
    mask-image: url("data:image/svg+xml,%3csvg width='220' height='190' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 95L55 0h110l55 95l-55 95H55L0 95Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e");
  }
}

@utility container {
  margin-inline: auto;
}
